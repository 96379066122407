var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"transaction-modal",attrs:{"id":"transaction","body-class":"main-scroll-v px-8 pt-8","centered":"","hide-footer":"","hide-header":"","scrollable":"","size":"sm"}},[_c('h3',{staticClass:"text-center font-weight-semi-bold text-color-1 mb-5"},[_vm._v(" "+_vm._s(_vm.$t("mentorcastHero.transactionDetails"))+" ")]),_c('div',{staticClass:"bg-light-gray"},[_c('b-row',{attrs:{"align-h":"center","no-gutters":""}},[_c('b-col',{staticClass:"pl-9 pr-7",attrs:{"cols":"12"}},[_vm._l((Object.keys(_vm.getTransaction)),function(item){return [(
              !_vm.transactionDetails[_vm.transaction.type][item].hasOwnProperty(
                'condition'
              ) || _vm.transactionDetails[_vm.transaction.type][item].condition()
            )?_c('b-row',{key:item,staticClass:"font-weight-medium py-5",attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"auto","sm":"6"}},[_c('inline-svg',{staticClass:"mr-2",attrs:{"src":require(`@/assets/icons/${
                    _vm.transactionDetails[_vm.transaction.type][item].icon
                  }.svg`),"fill":_vm.transactionDetails[_vm.transaction.type][item].icon ===
                  'transfer'
                    ? '#3E85D0'
                    : null,"height":"18","width":"18"}}),_vm._v(" "+_vm._s(_vm.transactionDetails[_vm.transaction.type][item].label)+" ")],1),_c('b-col',{staticClass:"text-dark pl-1 pr-sm-6",attrs:{"cols":"auto"}},[_vm._v(":")]),_c('b-col',{staticClass:"text-right text-sm-left mt-2 mt-sm-0 text-color-1",attrs:{"cols":"12","sm":"5"}},[_c('b-row',{class:_vm.transactionDetails[_vm.transaction.type][item].class || '',attrs:{"align-h":"between","no-gutters":""}},[(item == 'subject' && _vm.transaction.booking_route)?_c('router-link',{attrs:{"to":_vm.transaction.booking_route}},[_vm._v(" "+_vm._s(_vm.getSubject( _vm.resolveDynamic(item, _vm.transaction) || _vm.transactionDetails[_vm.transaction.type][item].value ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.resolveDynamic(item, _vm.transaction) || _vm.transactionDetails[_vm.transaction.type][item].value)+" ")]),(_vm.transactionDetails[_vm.transaction.type][item].percent)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_vm._v("("+_vm._s(_vm.resolveDynamic("percentages." + item, _vm.transaction))+"%) ")]):_vm._e()],1)],1)],1):_vm._e()]})],2)],1),_c('b-row',{staticClass:"pt-5 total",attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',{staticClass:"d-flex align-items-center font-weight-medium font-size-14 pl-9 pr-7",attrs:{"cols":"auto","sm":"6"}},[_c('inline-svg',{staticClass:"mr-1",attrs:{"src":_vm.transaction.type === 'topUp'
              ? require(`@/assets/icons/hand-price.svg`)
              : require(`@/assets/icons/outline-paid.svg`),"height":"20","width":"20"}}),_vm._v(" "+_vm._s(_vm.getTotalPaidMessage(_vm.transaction))+" ")],1),_c('b-col',{staticClass:"text-dark pl-4 pr-sm-6",attrs:{"cols":"auto"}},[_vm._v(":")]),_c('b-col',{staticClass:"text-right text-sm-left mt-2 mt-sm-0 font-weight-semi-bold",class:_vm.transaction.type === 'topUp' ? 'text-dark-green' : 'text-color-1',attrs:{"cols":"12","sm":""}},[_vm._v(_vm._s(_vm.transaction.type === "paid" ? _vm.numberWithCommas( parseFloat(_vm.transaction.total_price_paid).toFixed(2) ) : _vm.numberWithCommas(parseFloat(_vm.transaction.sub_total).toFixed(2)))+" "+_vm._s(_vm.platformCurrency)+" ")])],1),(
        !_vm.transaction.is_refunded &&
          !['withdrawal', 'topUp'].includes(_vm.transaction.type)
      )?_c('b-row',{staticClass:"pt-5 mt-3 total",attrs:{"align-v":"center","no-gutters":""}},[_c('p',{staticClass:"text-center m-auto font-size-16 cursor-pointer",staticStyle:{"color":"#3E85D0"},on:{"click":_vm.onViewInvoice}},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/file.svg`),"height":"14","width":"14"}}),_c('span',{staticClass:"text-underline"},[_vm._v("View Invoice")])],1)]):_vm._e()],1),_c('b-button',{staticClass:"position-absolute top-0 right-0 mr-7 mt-7",attrs:{"variant":"transparent"},on:{"click":() => _vm.$refs['transaction-modal'].hide()}},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/close.svg`),"fill":"#4E5D78","height":"12","width":"12"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }